<template>
  <section class="tabs">
    <!-- <div class="page-header">
    <h3 class="page-title">
      Configurações da Conta
    </h3>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="javascript:void(0);">UI Elements</a></li>
        <li class="breadcrumb-item active" aria-current="page">Tabs</li>
      </ol>
    </nav>
  </div> -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Configurações da Conta</h4>
            <b-tabs
              class="tab-minimal vertical-tab tab-solid tab-solid-primary"
              vertical
              nav-wrapper-class="w-25"
            >
              <b-tab active>
                <template slot="title">
                  <i class="mdi mdi-account"></i> Dados
                </template>
                <h4 class="card-title">Dados Pessoais</h4>
                <p class="card-description">Atualize seus dados pessoais.</p>
                <form class="forms-sample" @submit.stop.prevent="updateUser">
                  <b-form-group label="Nome" label-for="nomeInput">
                    <b-input-group>
                      <b-form-input
                        id="nomeInput"
                        type="text"
                        placeholder="Nome"
                        v-model="$v.user.nome.$model"
                        :state="
                          $v.user.nome.$dirty ? !$v.user.nome.$error : null
                        "
                        aria-describedby="nome-live-feedback"
                        maxlength="255"
                      ></b-form-input>
                      <b-form-invalid-feedback id="nome-live-feedback">
                        <template v-if="!$v.user.nome.required"
                          >Preenchimento obrigatório</template
                        >
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                  <div class="d-flex">
                    <b-button
                      type="submit"
                      variant="success"
                      class="mr-2"
                      :disabled="loading"
                    >
                      <template v-if="loading">
                        <b-spinner small type="grow"></b-spinner>
                        Processando...
                      </template>
                      <template v-else> Confirmar </template>
                    </b-button>
                  </div>
                </form>
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="mdi mdi-security"></i> Senha
                </template>
                <h4 class="card-title">Alteração de Senha</h4>
                <form
                  class="forms-sample"
                  @submit.stop.prevent="updatePassword"
                >
                  <b-form-group label="Nova Senha" label-for="passwordInput">
                    <b-input-group>
                      <b-input-group-prepend class="bg-transparent">
                        <b-input-group-text
                          tag="span"
                          class="bg-transparent border-right-0"
                        >
                          <i class="mdi mdi-lock-outline text-primary"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="passwordInput"
                        type="password"
                        placeholder="Nova Senha"
                        v-model="$v.passwords.password.$model"
                        :state="$v.passwords.password.$dirty ? !$v.passwords.password.$error : null"
                        aria-describedby="password-live-feedback"
                        class="border-left-0"
                        size="lg"
                      ></b-form-input>
                      <b-form-invalid-feedback id="password-live-feedback">
                        <template v-if="!$v.passwords.password.required"
                          >Preenchimento obrigatório</template
                        >
                        <template v-if="!$v.passwords.password.minLength"
                          >Senha deve ter
                          {{ $v.passwords.password.$params.minLength.min }} ou mais
                          caracteres</template
                        >
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group
                    label="Confirmar Nova Senha"
                    label-for="passwordConfirmationInput"
                  >
                    <b-input-group>
                      <b-input-group-prepend class="bg-transparent">
                        <b-input-group-text
                          tag="span"
                          class="bg-transparent border-right-0"
                        >
                          <i class="mdi mdi-lock-outline text-primary"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="passwordConfirmationInput"
                        type="password"
                        placeholder="Confirmar Nova Senha"
                        v-model="$v.passwords.password_confirmation.$model"
                        :state="
                          $v.passwords.password_confirmation.$dirty
                            ? !$v.passwords.password_confirmation.$error
                            : null
                        "
                        aria-describedby="password_confirmation-live-feedback"
                        class="border-left-0"
                        size="lg"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="password_confirmation-live-feedback"
                      >
                        <template
                          v-if="!$v.passwords.password_confirmation.sameAsPassword"
                          >Senhas não conferem</template
                        >
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                  <div class="d-flex">
                    <b-button
                      type="submit"
                      variant="success"
                      class="mr-2"
                      :disabled="loading"
                    >
                      <template v-if="loading">
                        <b-spinner small type="grow"></b-spinner>
                        Processando...
                      </template>
                      <template v-else> Confirmar </template>
                    </b-button>
                  </div>
                </form>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  required,
  // email,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
import User from "@/models/user";
import AuthService from "../../services/auth.service";

export default {
  name: "Perfil",
  data() {
    return {
      user: new User(),
      passwords: {
        password_confirmation: null,
        password: null,
      },
      loading: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.dispatch("auth/update").then(
        (user) => {
          this.user = new User(user.email, null, null, user.nome);
        },
        (error) => {
          this.loading = false;
          if (error.response) {
            this.message = error.response.data.message;
          }
        }
      );
    },
    updateUser() {
      this.loading = true;
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        this.loading = false;
        return;
      }

      const promise = AuthService.updateUser(this.user);
      return promise
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "Sucesso!",
            autoHideDelay: 1000,
            toaster: "b-toaster-bottom-left",
            variant: "success",
          });
          this.$store.dispatch("auth/update");
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },

    updatePassword() {
      this.loading = true;
      this.$v.passwords.$touch();
      if (this.$v.passwords.$anyError) {
        this.loading = false;
        return;
      }

      const promise = AuthService.updatePassword(this.passwords.password);
      return promise
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "Sucesso!",
            autoHideDelay: 1000,
            toaster: "b-toaster-bottom-left",
            variant: "success",
          });
          this.passwords.password = null;
          this.passwords.password_confirmation = null;
          this.$v.passwords.$reset();
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
  },
  validations: {
    user: {
      nome: {
        required,
        maxLength: maxLength(255),
      },
    },
    passwords: {
      password: {
        required,
        minLength: minLength(6),
      },
      password_confirmation: {
        sameAsPassword: sameAs("password"),
      },
    }
  },
};
</script>